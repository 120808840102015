
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Subscription } from '@/util/promo'
import KionSelect, { KionSelectOption } from '@/components/Shared/Select.vue'
import KionCard from '@/components/Shared/Cards/Card.vue'
import KionSmallCard from '@/components/Shared/Cards/SmallCard.vue'
import { ssoPhoneToMasked, tariffPeriodTranslate } from '@/util/helpers'
import { httpService } from '@/util/HttpAdapter'
import KionButton from '@/components/Shared/Button.vue'
import { PaymentStep } from '@/views/main/components/Subscriptions.vue'

@Component({components: {KionSelect, KionCard, KionSmallCard, KionButton}})
export default class KionPaymentInit extends Vue {
  @Prop({default: null}) subscription!: Subscription
  selectedValue: PaymentStep | null = null
  options: KionSelectOption[] = [
    {
      label: ssoPhoneToMasked(httpService.phone),
      tooltip: 'Для абонентов МТС',
      icon: 'phone',
      step: PaymentStep.Phone
    },
    {
      label: 'Банковской картой',
      tooltip: 'Для абонентов любых операторов',
      icon: 'card',
      step: PaymentStep.Card
    }
  ]
  translate = tariffPeriodTranslate

  async mounted() {
   const { saleBlock } = (await httpService.isMtsAccount()).data
      console.log('saleBlock ----' + saleBlock)
   if (saleBlock) {
     this.options = [
      {
        label: 'Банковской картой',
        tooltip: 'Для абонентов любых операторов',
        icon: 'card',
        step: PaymentStep.Card
      }
     ]
   }
  }
  changeValue(val: number) {
    this.selectedValue = val
  }
  onNextStep() {
    this.$emit('next', this.selectedValue)
  }
}
