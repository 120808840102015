
import { Component, Vue } from 'vue-property-decorator'
import KionAnimatedColumn from '@/components/Footer/AnimatedColumn.vue'
import KionCopyright from '@/components/Shared/Copyright.vue'
import {httpService} from "@/util/HttpAdapter";
import {AxiosResponse} from "axios";

@Component({
  components: {
    KionAnimatedColumn,
    KionCopyright
  }
})
export default class KionFooter extends Vue {
  images: any[][] = []

  async mounted() {
    const imgs = (await httpService.getImgsForSwapper() as AxiosResponse).data.items
    const arr = []
    let subArr = []
    for (let g = 1, r = 1; g < 6; g++) {
      for (let i = 1; i <= 4; i++, r++) {
        subArr.push(imgs[r].imageUrl)
      }
      arr.push(subArr)
      subArr = []
    }
    this.images = arr
  }

}
