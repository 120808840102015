

import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class KionModal extends Vue {

  @Prop({ default: false }) backVisible!: boolean

  @Emit() close(event: MouseEvent) {
    return event
  }

  @Emit() back(event: MouseEvent) {
    return event
  }
}
