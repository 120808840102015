
import { Component, Vue } from 'vue-property-decorator'
import { gtm } from "@/util/GTMService";
import {getClientID} from "@/util/helpers";

@Component({})
export default class App extends Vue {
  mounted() {
    gtm.clearSessionSaveOldRequestId(window.sessionStorage.getItem("request_id"), window.sessionStorage.getItem("analyticsQuery"))
    const intervalId = setInterval(() => {
      if (getClientID()) {
        clearInterval(intervalId)
        gtm.pushEvent({
          event_name: 'screen_show'
        })
      }
    }, 500)
  }
}
