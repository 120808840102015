
import { Component, Vue } from 'vue-property-decorator'
import { httpService } from '@/util/HttpAdapter'
import KionButton from '@/components/Shared/Button.vue'
import KionTemplate from '@/components/Shared/Template.vue'
import KionInput from '@/components/Shared/Input.vue'
import { AxiosResponse } from 'axios'
import { KION_ERROR_PAGE_TYPE, KION_PROMO_STATUS } from '@/util/interfaces'

@Component({
  components: {
    KionTemplate,
    KionButton,
    KionInput
  }
})
export default class KionPromoError extends Vue {
  code = httpService.promoCode
  messageError = httpService.messageError
  codeError: string | null = null
  loading = false

  async checkPromo() {
    this.loading = true
    try {
      httpService.promoCode = this.code
      const promoResp = (await httpService.getSubscriptionList() as AxiosResponse).data

      if (promoResp.voucher.promoCodeMessage && promoResp.voucher.status !== KION_PROMO_STATUS.ACTIVE) {
        this.loading = false
        this.setCodeErrors(promoResp.voucher.promoCodeMessage)

        return
      }

      if (promoResp.promoProductPrices.length > 0) {
        await this.$router.push('/subscriptions')
      }

    } catch (err) {
      this.loading = false
      const errCode = err?.response?.data?.errorCode
      if (errCode === 'ObjectAlreadyExistsException') {
        this.$router.push('/error')
        console.error('Промокод уже был активирован')
        return
      }

      await this.$router.push('/error')
    }
  }

  setCodeErrors(error?: string) {
    this.code = this.code.trim()
    if (error) {
      this.codeError = error
      this.messageError = error
      return
    }

    if (!this.code) {
      this.codeError = 'Обязательное поле'
      return
    }

    this.codeError = null
  }
}
