
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Subscription } from '@/util/promo'
import { tariffPeriodTranslate } from '@/util/helpers'
import KionButton from "@/components/Shared/Button.vue";

@Component({
  components: { KionButton}
})
export default class KionCard extends Vue {
  @Prop({ default: null }) subscription!: Subscription
  @Prop( {default: true}) showButton!: boolean

  getTariff(): string {
    return tariffPeriodTranslate(this.subscription.tariffPeriod)
  }

  onButtonClick() {
    this.$emit('select', this.subscription)
  }

  transformPrice(price: number) {
    return price / 100
  }

  getBackgroundImage() {
    if(this.subscription.image === null) {
      return null
    }
    const images = require.context('/src/assets/promo-images/', false, /\.(png|jpg)$/)
    return images('./' + this.subscription.image)
  }
}
