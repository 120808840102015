

import { Component, Vue } from 'vue-property-decorator'
import { mainService } from '@/util/mainService'
import {AxiosResponse} from "axios";
import {KionPostersItemResponse, SHELVES_ITEM_LINK} from "@/util/interfaces";
import {httpService} from "@/util/HttpAdapter";

@Component({})
export default class KionMain extends Vue {
	isFooterVisible = true
	isActive = false;
	images: any[] = []

	async mounted() {
	const imgs = (await httpService.getImgsForSerial() as AxiosResponse).data.items
		.map((item: KionPostersItemResponse) => {
			let link;
			if (item.link.linkType === SHELVES_ITEM_LINK.MOVIE) {
				link = "https://kion.ru/video/movie/" + item.link.seriesContentId;
			} else {
				link = "https://kion.ru/video/serial/" + item.link.seriesContentId;
			}
			return {
				imageUrl: item.imageUrl,
				link: link,
			}
		})
	this.images = imgs;
	mainService.isFooterVisible.subscribe( condition => this.isFooterVisible = condition)
    if (this.checkCurrentUrl()) {
      this.isActive = true;
    }
  }

  checkCurrentUrl(): boolean {
	return location.href.endsWith('/success')
  }

  showVideo(link: string) {
	if (this.checkCurrentUrl()) {
		window.location.assign(encodeURI(link))
	}
  }

}
