import { httpService } from "@/util/HttpAdapter"
import { getClientID } from "@/util/helpers"
import router from "@/router"
import { WBProviderService } from '../wbc'

export type DLEvent = 'screen_show' | 'registration' | 'authorization_success' | 'promocode_success' | 'subscription_success' | 'authorization_start' | 'subscribe_error' | 'kionProd'

export type DLEventProfile = 'mts_guest' | string

export interface DataLayer {
    event?: DLEvent | null
    event_name?: DLEvent | null
    userId?: string | null
    client_id?: string | null
    event_datetime?: string | null
    screen?: string | null
    auth_method?: string | null
    auth_type?: 'mtstv' | 'websso' | 'seamless' | null
    request_id?: string | null
    experiments?: string[] | null
    huawei_subscriber_id?: DLEventProfile | null
    profile_id?: DLEventProfile | null
    profile_age?: string | null
    referer?: string | null
    error_message?: string | null
    price?: number | null
    subscription_name?: string | null
    subscription_id?: string | null
    promocode?: string | null
    deeplink?: string | null
    platform?: string | null
    is_trial?: 0 | 1 | null
    transaction_id?: string | null
    payment_type?: "vps" | "inapp" | "account" | "mts_money" | null
    waterbase_device_id?: string
}

const wbc = new WBProviderService()

declare const dataLayer: DataLayer[]
declare const dataLayerKION: DataLayer[]
declare const dataLayerMTS: DataLayer[]

export class GTMService {
    public pushEvent(_dl: DataLayer = {}) {
        const data = {
            ...GTMService._getCommonData(),
            ..._dl
        }

        if (process.env.NODE_ENV === 'production') {
            dataLayer.push(data)
            dataLayerKION.push(data);
            dataLayerMTS.push(data);
        } else {
            console.info('[GTM] Data pushed to dataLayer', data)
            console.info('[GTM] Data pushed to dataLayerKION', data)
            console.info('[GTM] Data pushed to dataLayerMTS', data)
        }
    }

    public clearSessionSaveOldRequestId(requestId: string | null, analyticsQuery: string | null) {
        window.sessionStorage.clear()
        if (requestId) {
            window.sessionStorage.setItem("request_id", requestId)
        }
        if (analyticsQuery) {
            window.sessionStorage.setItem("analyticsQuery", analyticsQuery)
        }
    }

    public getAnalyticsQuery() {
        let text: any = window.sessionStorage.getItem("analyticsQuery")
        if (text) {
            text = JSON.parse(text)
        } else {
            text = undefined
        }
        return text;
    }

    private static _getCommonData(): DataLayer {
        return {
            event: 'screen_show',
            event_name: 'screen_show',
            userId: httpService.userId,
            screen: '/promocode_landing' + router.currentRoute.path,
            client_id: getClientID(),
            event_datetime: new Date().toISOString(),
            experiments: [],
            huawei_subscriber_id: httpService.grclientid || 'mts_guest',
            profile_id: httpService.grclientid || 'mts_guest',
            platform: 'web30_landing_code',
            profile_age: null,
            waterbase_device_id: wbc.duid
        }
    }
}

export const gtm = new GTMService()