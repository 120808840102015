
import { Component, Vue } from 'vue-property-decorator'
import { AxiosResponse } from 'axios'
import { httpService } from '@/util/HttpAdapter'

@Component({})
export default class KionOffer extends Vue {
  loading = true
  content = ``
  createdAt: Date = new Date()

  mounted() {
    httpService.getLastOfferMarkup()
        .then((res: AxiosResponse) => {
          this.content = res.data.content
          this.createdAt = new Date(res.data.created)
          this.loading = false
        })
        .catch(err => {
          this.content = `
              <div>При загрузке произошла ошибка:</div>
              <div>${ err }</div>
          `
          this.loading = false
        })
  }

  updated() {
    let container = document.querySelector('.agreement-container')
    if (container) {
      const day = this.createdAt.getDate()
      const month = this.createdAt.getMonth()
      const year = this.createdAt.getFullYear()

      let el = document.createElement('div')
      el.classList.add('date')
      el.textContent = `Версия от ${ day } ${ this.translateMonth(month) } ${ year } г.`

      container?.firstElementChild?.insertAdjacentElement('afterend', el)
    }
  }

  translateMonth(month = 0): string {
    return ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'][month]
  }
}
