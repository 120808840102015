import axios from 'axios'

const client = axios.create({
    baseURL: '/code/api'
})

client.interceptors.response.use(
    response => Promise.resolve(response),
    error => {
        // Event.$emit('error', 500, error.response.data.message)
        // if (error.response.status === 401) AuthService.logout()
        console.log(error)
        console.log(error.response.status)
        const errorMessage = error.response.data.message
        console.log(errorMessage)
        error.response.data.message = errorMessage.length > 200
            ? JSON.parse(errorMessage.split('code :').pop()).error.message.split(':')[0]
            : errorMessage
        throw error
        // Promise.reject(error)
    }
)

export default client