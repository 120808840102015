
import KionTemplate from '@/components/Shared/Template.vue'
import KionButton from '@/components/Shared/Button.vue'
import { httpService } from '@/util/HttpAdapter'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {gtm} from "@/util/GTMService";
import {PROMO, Subscription} from "@/util/promo";

@Component({
  components: {
    KionButton,
    KionTemplate
  }
})
export default class KionSuccess extends Vue {
  @Prop({ default: null }) subscription!: Subscription
   code: null | string = httpService.promoCode

  moveTo(link: string) {
    window.location.assign(link)
  }
}
