
import { Component, Vue } from 'vue-property-decorator'
import KionButton from '@/components/Shared/Button.vue'
import KionTemplate from '@/components/Shared/Template.vue'
import KionInput from '@/components/Shared/Input.vue'
import {httpService, tvhEnvironmentApi} from "@/util/HttpAdapter";
import {gtm} from "@/util/GTMService";
import {v4 as uuidv4} from "uuid";

@Component({
  components: {
    KionTemplate,
    KionButton,
    KionInput
  }
})
export default class KionCode extends Vue {
  qr: string | null = null
  code = ''
  codeError: string | null = null
  loading = false

  mounted() {
    const q = this.$route.query
    if (typeof q?.pq === 'string') {
      this.code = q.pq.trim().toUpperCase()
      this.webSsoAuth()
    }
    if (typeof q?.PQ === 'string') {
      this.code = q.PQ.trim().toUpperCase()
      this.webSsoAuth()
    }
  }

  get isButtonEnable(): boolean {
    return !!this.code
  }

  webSsoAuth() {
    window.sessionStorage.setItem("request_id", uuidv4())
    gtm.pushEvent({
      event: 'authorization_start',
      event_name: 'authorization_start',
      referer: httpService.previousPage,
      auth_method: "sms",
      auth_type: 'websso',
      request_id: window.sessionStorage.getItem("request_id")
    })
    const hostUrl = window.location.host
    window.location.assign(encodeURI(`https://${tvhEnvironmentApi}/tvh-public-api-gateway/public/rest/general/websso/redirect?state=${ this.code.toUpperCase() }&clientType=WEB&domain=${hostUrl}/code`))
  }

  setCodeErrors(error?: string) {
    this.code = this.code.trim()
    if (error) {
      this.codeError = error
      return
    }

    if (!this.code) {
      this.codeError = 'Обязательное поле'
      return
    }

    this.codeError = null
  }
}
