
import { Component, Prop, Vue } from 'vue-property-decorator'
import Template from '@/components/Shared/Template.vue'

export type PaymentIconName = 'phone' | 'card'

export interface KionSelectOption {
  label: string
  icon?: PaymentIconName
  tooltip?: string
  step: number
}

@Component({
  components: { Template }
})
export default class KionSelect extends Vue {
  @Prop({ default: '' }) placeholder!: string
  @Prop({ default: '' }) label!: string
  @Prop({ default: () => [] }) options!: KionSelectOption[]
  selected: KionSelectOption | null = null
  isOpen = false

  openOptions() {
    this.isOpen = true
    document.body.addEventListener('mouseup', this.outsideClickHandler)
  }

  closeOptions() {
    this.isOpen = false
    document.body.removeEventListener('mouseup', this.outsideClickHandler)
  }

  private outsideClickHandler(e: MouseEvent) {
    const dropdown = this.$refs.dropdown as HTMLElement
    if (e.target !== dropdown && !dropdown.contains(e.target as HTMLElement)) {
      this.closeOptions()
     }
  }

  onSelect(opt: KionSelectOption) {
    this.selected = opt
    this.$emit('select', opt.step)
    this.closeOptions()
  }

  getPaymentIcon(iconName: PaymentIconName) {
    const images = require.context('/src/assets/payment-icons/', false, /\.svg$/)
    return images('./' + iconName + '.svg')
  }
}

