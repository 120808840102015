import { KION_TARIFF_PERIOD, KionPromoPrice, KionPromoPriceFragment } from '@/util/interfaces'
import { transcriptRuEn } from '@/util/helpers'

export enum KION_PICTURE_TYPE {
  SMALL,
  BIG
}

export interface HardcodedSubscription {
  name: string,
  description: string,
  transcription: string,
  image: string | null,
  ageRating: number,
  isBigPicture: KION_PICTURE_TYPE,
}

export interface Subscription extends HardcodedSubscription, KionPromoPriceFragment {
}

export const PROMO: { [key: string]: Omit<HardcodedSubscription, 'transcription'> } = {
  "cinema_light_svod_per_month_mts_ott_sdhd4k_b2c_v1_12_2020": {
    "name": "Кино Lite",
    "description": "Познакомьтесь с Kion. Подборка хорошего кино для вас",
    "image": "202012141333035414js.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "a_lot_of_tv_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2020": {
    "name": "Весь МТС. KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20231123145419533jd5.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "more_tv_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2020": {
    "name": "Весь МТС. KION",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "2023112315205443233t.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "livetv_movie_v4_live_per_month_mts_ott_sdhd_b2c_08_2019": {
    "name": "НАСТРОЙ КИНО!",
    "description": "Для тех, кто готов смотреть кино 24/7",
    "image": "202305251324229444z2.jpg",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "livetv_match_v4_live_per_month_mts_ott_sdhd_b2c_08_2019": {
    "name": "МАТЧ ПРЕМЬЕР",
    "description": "Всё о российском футболе",
    "image": "202104191939565530v8.png",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "livetv_amedia_live_per_month_mts_ott_sdhd_b2c_v1_05_2020": {
    "name": "Амедиа Премиум HD",
    "description": "ТВ-каналы с зарубежными сериалами и фильмами",
    "image": "20230130065839543gw0.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "livetv_sport_v1_live_per_month_mts_ott_sdhd_b2c_10_2019": {
    "name": "Спорт",
    "description": "Всё, что нужно любителю спорта",
    "image": "20220726210057428utc.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "livetv_ocean_live_per_month_mts_ott_sdhd_b2c__v1_03_2020": {
    "name": "Океан открытий",
    "description": "Пакет каналов от создателей Discovery Channel",
    "image": "202104191947115380h7.png",
    "ageRating": 16,
    "isBigPicture": 0
  },
  "livetv_football_live_per_month_mts_ott_sdhd_b2c__v1_07_2020": {
    "name": "МАТЧ! ФУТБОЛ",
    "description": "ВСЕ о футболе в HD-качестве",
    "image": "20220720083018337fwf.jpg",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "ivi_svod_svod_per_month_mts_ott_sdhd4k_b2c_v5_05_2020": {
    "name": "Иви лайт",
    "description": "Специальная подписка IVI Лайт для Kion",
    "image": "20220727232508308nzi.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "start_svod_prizes_v4_svod_per_month_mts_ott_sdhd4k_b2c_10_2019": {
    "name": "START prizes",
    "description": "Подписка на START для Kion",
    "image": "2021041920232654325c.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "start_all_mts_svod_per_month_mts_ott_sdhd4k_b2c_v1_01_2021": {
    "name": "Весь МТС. START",
    "description": "\"Подписка на START в тарифе \"\"Весь МТС\"\"\"",
    "image": "20210419202741537irq.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "amediateka_svod_prizes_v1_svod_per_month_mts_ott_sdhd4k_b2c_10_2019": {
    "name": "AMEDIATEKA prizes",
    "description": "Самые ожидаемые сериалы планеты",
    "image": "20230130162859267rph.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "amediateka_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_01_2021": {
    "name": "Весь МТС. Amediateka",
    "description": "\"Самые ожидаемые сериалы планеты в тарифе \"\"Весь МТС\"\"\"",
    "image": "20230130162959604xta.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "childhood_svod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "Детям",
    "description": "Любимые мультфильмы, фильмы и обучающие сериалы",
    "image": "20220518144740594gee.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "adult_svod_per_month_mts_ott_sdhd4k_b2c_v_1_05_2020": {
    "name": "После полуночи",
    "description": "Для тех, кто не спит.",
    "image": "20220518144637341pfx.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "amediateka_svod_v1_svod_per_month_mts_ott_sdhd4k_b2c_10_2019": {
    "name": "AMEDIATEKA",
    "description": "Самые ожидаемые сериалы планеты",
    "image": "20230130162804612iwu.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "a_lot_of_tv_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "202403201536397745ex.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2020": {
    "name": "KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "2024032015373957601p.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "super_bvod_per_year_mts_ott_sdhd4k_b2c_v1_12_2020": {
    "name": "KION Light 12 месяцев",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "202403201536397745ex.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "more_tv_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320161354682053.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "a_lot_of_tv_prizes_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "KION 2 мес. в подарок",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320153542983avf.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "more_tv_prizes_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2020": {
    "name": "KION prizes",
    "description": "\"\"\"KION\"\" + фильмы и сериалы студий Sony и Universal и 200+ ТВ-каналов\"",
    "image": "20240320161247990h64.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "a_lot_of_tv_premium_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2020": {
    "name": "KION для Premium",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, привилегии МТС Premium",
    "image": "202308030838080036eh.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "more_tv_for_smart_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2020": {
    "name": "ТВ для Своих",
    "description": "Более 150 ТВ-каналов, тысячи фильмов, сериалов и мультиков",
    "image": "20201214133851413g0l.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "a_lot_of_tv_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_01_2021": {
    "name": "НЕТАРИФ KION Light",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 130+ ТВ-каналов",
    "image": "20210419210330394wo6.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "more_tv_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_11_2020": {
    "name": "НЕТАРИФ KION",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов",
    "image": "20210419210151550ty9.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "top_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2021": {
    "name": "KION ТОП light",
    "description": "Смотри 3 онлайн-кинотеатра: KION, IVI Лайт, START и 200+ ТВ-каналов в одной подписке",
    "image": "20210422090640434vw5.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "top_bvod_per_month_mts_ott_sdhd4k_b2c_v2_03_2021": {
    "name": "KION ТОП",
    "description": "Смотри в одной подписке: KION, AMEDIATEKA, START, IVI Лайт и более 200 ТВ-каналов.",
    "image": "20221110114144314dx8.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "more_tv_bvod_per_day_mts_ott_sdhd4k_b2c_v1_03_2021": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320161354682053.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "super_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2021": {
    "name": "Весь МТС. Полный дом. KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "202311231457443145jy.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2021": {
    "name": "KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20240320161439621gss.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_04_2021": {
    "name": "KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20240320161439621gss.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "superplus_bvod_per_year_mts_ott_sdhd4k_b2c_v1_04_2021": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201525531118iz.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "superplus_bvod_per_month_mts_ott_sdhd4k_b2c_v2_04_2021": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201525531118iz.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "stb_ott_svod_per_month_mts_ott_sdhd4k_b2c_v1_04_2021": {
    "name": "Продажа приставки. KION на 3 месяца",
    "description": "Приставка МТС и 3 месяца подписки KION",
    "image": "20210521114919724os1.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "stb_router_ott_svod_per_month_mts_ott_sdhd4k_b2c_v1_04_2021": {
    "name": "Комплект приставка + роутер",
    "description": "Приставка МТС ТВ, Роутер",
    "image": "202105211151047230my.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "stb_ott_svod_per_year_mts_ott_sdhd4k_b2c_v1_05_2021": {
    "name": "Продажа приставки. KION на 12 месяцев",
    "description": "Приставка МТС и 12 месяцев подписки KION",
    "image": "20210521114604725e0k.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "more_tv_for_nb_bvod_per_day_mts_ott_sdhd4k_b2c_v1_05_2021": {
    "name": "ТВ для ноутбука",
    "description": "Любимые фильмы и ТВ в подарок!",
    "image": "20210521113607509m7o.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "adult_svod_per_month_mts_ott_sdhd4k_b2c_v_1_05_2021": {
    "name": "После полуночи",
    "description": "Для тех, кто не спит.",
    "image": "20220518144637341pfx.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2021": {
    "name": "KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20240320161439621gss.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "superplus_we_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2021": {
    "name": "МЫ МТС. KION",
    "description": "\"\"\"KION\"\" + фильмы и сериалы студий Sony и Universal и 200+ ТВ-каналов\"",
    "image": "20231123152149559xq2.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "super_corp_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2021": {
    "name": "CORP. KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20240320161537959lxd.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kionlight_all_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "Весь МТС. KION Light",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "202311231455580056an.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_all_mts_super_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "KION для вашего тарифа",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123145844574ugk.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_we_mts_plus_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "МЫ МТС+. KION",
    "description": "\"\"\"KION\"\" + фильмы и сериалы студий Sony и Universal и 200+ ТВ-каналов\"",
    "image": "20231123145844574ugk.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_all_mts_extra_bvod_per_month_mts_ott_sdhd4k_b2c_v1_09_2021": {
    "name": "Весь МТС Экстра. KION",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123151939644nsw.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_12m_bvod_per_year_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "KION 12 месяцев",
    "description": "Еще больше эмоций. Расширенная библиотека фильмов, сериалов и мультфильмов, 180+ ТВ-каналов.",
    "image": "20240320152834332j0z.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2021": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201608546395k1.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_09_2021": {
    "name": "НЕТАРИФ KION",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов",
    "image": "20210909103113321lj2.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kionlight_3m_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v2_09_2021": {
    "name": "KION Light 3 месяца",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20240320153259635e8j.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kionlight_12m_bvod_per_year_mts_ott_sdhd4k_b2c_v1_09_2021": {
    "name": "KION Light 12 месяцев",
    "description": "Тысячи фильмов, мультфильмов, оригинальные сериалы и 130+ ТВ-каналов",
    "image": "20240320153212950884.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_month_mts_ott_sdhd4k_b2c_v1_09_2021": {
    "name": "KION 2 месяца",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240319153632977n55.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_3m_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v2_08_2021": {
    "name": "KION 3 месяца",
    "description": "Еще больше эмоций. Расширенная библиотека фильмов, сериалов и мультфильмов, 200+ ТВ-каналов.",
    "image": "20240320152709664nkc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_6m_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v2_08_2021": {
    "name": "KION 6 месяцев",
    "description": "Еще больше эмоций. Расширенная библиотека фильмов, сериалов и мультфильмов, 200+ ТВ-каналов.",
    "image": "20240320153124333mcl.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_month_mts_ott_sdhd4k_b2c_v2_09_2021": {
    "name": "KION",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320160754302jq1.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_month_mts_ott_sdhd4k_b2c_v3_09_2021": {
    "name": "KION",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320160754302jq1.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_junior_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_10_2021": {
    "name": "Junior. KION",
    "description": "Детские ТВ-каналы, мультфильмы, кино и сериалы",
    "image": "20211111111345450wh8.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "test_3m_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_11_2021": {
    "name": "Тест. KION 3 месяца",
    "description": "Еще больше эмоций. Расширенная библиотека фильмов, сериалов и мультфильмов, 180+ ТВ-каналов.",
    "image": "20211102095519155jip.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "livetv_movie_live_per_month_mts_ott_sdhd_b2c_v1_11_2021": {
    "name": "НАСТРОЙ КИНО!",
    "description": "Для тех, кто готов смотреть кино 24/7",
    "image": "202305251324229444z2.jpg",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "kion_bvod_per_year_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "KION 12 месяцев",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201606446473i7.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "livetv_match_live_per_month_mts_ott_sdhd_b2c_v1_11_2021": {
    "name": "МАТЧ ПРЕМЬЕР",
    "description": "Всё о российском футболе! После промо - 299 руб./мес.",
    "image": "20220829160504483clv.png",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "match_we_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "МЫ МТС. KION",
    "description": "Смотри тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123152242953xmp.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "match_we_mts_plus_bvod_per_month_mts_ott_sdhd4k_b2c_v1_11_2021": {
    "name": "МЫ МТС+. KION",
    "description": "Смотри тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123152542980zwl.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "top_bvod_per_month_mts_ott_sdhd4k_b2c_v3_12_2021": {
    "name": "KION ТОП",
    "description": "В одной подписке: KION, AMEDIATEKA, IVI Лайт, START и 200+ ТВ-каналов.",
    "image": "20211216010053074u4t.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_ozon_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "KION OZON",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов.",
    "image": "20220422123408712nff.png",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kionlight_premium_bvod_per_year_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "KION для Premium",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, привилегии МТС Premium",
    "image": "202308030838080036eh.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "start_svod_per_month_mts_ott_sdhd4k_b2c_v5_12_2021": {
    "name": "START",
    "description": "Подписка на START для Kion",
    "image": "2021041920090810521m.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "top_bvod_per_month_mts_ott_sdhd4k_b2c_v4_12_2021": {
    "name": "KION ТОП",
    "description": "Смотри 4 онлайн-кинотеатра: KION, IVI Лайт, AMEDIATEKA, START и 200+ ТВ-каналов в одной подписке по спец цене",
    "image": "202112271048530035mh.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "test_product_svod_per_month_mts_ott_sdhd4k_b2c_v1_12_2021": {
    "name": "Тестирование продуктов KION",
    "description": "Тестирование продуктов",
    "image": "20211224130949155588.png",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "kion_2m_bvod_per_multimonth2_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "KION 2 месяца",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320153039583gor.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "mobile_router_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "Продажа мобильного роутера",
    "description": "Роутер, ТП для ноутбука, подписка KION",
    "image": "202311011058045206ce.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "cpe_router_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "Продажа CPE роутера",
    "description": "Роутер, ТП для ноутбука, подписка KION",
    "image": "20231101110014520acx.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "modem_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "Продажа модема",
    "description": "Модем, ТП для ноутбука, подписка KION",
    "image": "20231101110142931zlv.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "panel_antenna_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "Продажа панельной антенны",
    "description": "Панельная антенна, ТП для ноутбука, подписка KION",
    "image": "20231101110304257x8b.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "stb_ott_svod_per_month_mts_ott_sdhd4k_b2c_v1_02_2022": {
    "name": "KION. Продажа приставки 1000",
    "description": "Приставка и 3 месяца подписки KION",
    "image": "20220228113500157hke.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_1m_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320152929678cwc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_autopay_bvod_per_month_mts_ott_sdhd4k_b2c_v1_04_2022": {
    "name": "KION + Автоплатеж",
    "description": "Скидка 20% абонентам ?Тарифище? и ?Автоплатёжа? на подписку с сериалами KION, фильмами, ТВ",
    "image": "20230803092804617zfd.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_04_2022": {
    "name": "KION 3 месяца",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320160949331x1b.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_04_2022": {
    "name": "KION 6 месяцев",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201606446473i7.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "livetv_match_mh_live_per_month_mts_ott_sdhd_b2c_v1_04_2022": {
    "name": "МАТЧ ПРЕМЬЕР",
    "description": "Всё о российском футболе! После промо - 299 руб./мес.",
    "image": "20220829160504483clv.png",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "childhood_svod_per_month_mts_ott_sdhd4k_b2c_v1_05_2022": {
    "name": "Детям",
    "description": "Любимые мультфильмы, фильмы и обучающие сериалы",
    "image": "20220524121508287rvv.jpg",
    "ageRating": 0,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2022": {
    "name": "KION+Premium",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20230901080318016ztm.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_149_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2022": {
    "name": "KION+Premium",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "20230803092917955npk.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_149_bvod_per_month_mts_ott_sdhd4k_b2c_v2_05_2022": {
    "name": "KION+Premium",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "2023080309294953798x.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_199_bvod_per_month_mts_ott_sdhd4k_b2c_v2_05_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "202403201608546395k1.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_299_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2022": {
    "name": "KION+Premium 2023",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "20230803093019634o9p.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_299_bvod_per_month_mts_ott_sdhd4k_b2c_v2_05_2022": {
    "name": "KION+Premium 2023",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "20230803093049291fjr.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "match_not_tp_bvod_per_day_mts_ott_sdhd4k_b2c_v1_11_2021": {
    "name": "НЕТАРИФ KION",
    "description": "Смотри тысячи фильмов, сериалов, мультфильмов и 200+ ТВ-каналов",
    "image": "20230802101044353jgp.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_amedia_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2022": {
    "name": "KION + AMEDIATEKA",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_for_nb_bvod_per_day_mts_ott_sdhd4k_b2c_v1_06_2022": {
    "name": "ТВ для ноутбука",
    "description": "Любимые фильмы и ТВ в подарок!",
    "image": "20210521113607509m7o.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_prem_bvod_per_month_mts_ott_sdhd4k_b2c_v2_07_2022": {
    "name": "KION+Premium",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20240424075905448agi.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_for_premium_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2022": {
    "name": "KION для Premium",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, привилегии МТС Premium",
    "image": "20230803083849561k28.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_for_premium_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2022": {
    "name": "KION для Premium",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, привилегии МТС Premium",
    "image": "20230803083849561k28.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_stb_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2022": {
    "name": "KION 12 месяцев",
    "description": "Бесплатный доступ к фильмам и сериалам, 200+ ТВ-каналов, привилегии МТС Premium",
    "image": "20230803094304534le8.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_mts_junior_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2022": {
    "name": "МТС Junior. KION",
    "description": "Детские ТВ-каналы, мультфильмы, кино и сериалы",
    "image": "20220812110043293bb9.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_cashback_bvod_per_month_mts_ott_sdhd4k_b2c_v1_08_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320153839684hsc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_09_2022": {
    "name": "KION+Premium 3 месяца",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20240424075905448agi.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_09_2022": {
    "name": "KION+Premium 6 месяцев",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20240424075905448agi.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_year_mts_ott_sdhd4k_b2c_v1_09_2022": {
    "name": "KION+Premium 12 месяцев",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20240424075905448agi.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "stb_ott_svod_per_month_mts_ott_sdhd4k_b2c_v2_09_2022": {
    "name": "Продажа приставки OTT",
    "description": "Продажа приставки OTT",
    "image": "20220902145202405eqd.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_for_nb_mini_bvod_per_day_mts_ott_sdhd4k_b2c_v1_10_2022": {
    "name": "ТВ для ноутбука мини",
    "description": "Любимые фильмы и ТВ в подарок!",
    "image": "20221028111949634hve.png",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_prem_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v2_09_2022": {
    "name": "KION+Premium 3 месяца",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20230803094214607w9u.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v2_09_2022": {
    "name": "KION+Premium 6 месяцев",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "202308030939596092bu.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_year_mts_ott_sdhd4k_b2c_v2_09_2022": {
    "name": "KION+Premium 12 месяцев",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "202308030939596092bu.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_payment_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, привилегии МТС Premium",
    "image": "20230803093809298pj0.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_amedia_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION + AMEDIATEKA 3 месяца",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_amedia_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION + AMEDIATEKA 6 месяцев",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_amedia_bvod_per_year_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION + AMEDIATEKA 12 месяцев",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_ozon_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION 2023 часа",
    "description": "Оригинальные сериалы KION, 200+ ТВ-каналов, тысячи фильмов, мультфильмов",
    "image": "202403201601396472hu.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "match_we_mts_bvod_per_month_mts_ott_sdhd4k_b2c_v2_12_2022": {
    "name": "МЫ МТС. KION",
    "description": "Смотри тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123152343023v09.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "match_we_mts_plus_bvod_per_month_mts_ott_sdhd4k_b2c_v2_12_2022": {
    "name": "МЫ МТС+. KION",
    "description": "Смотри тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123152639660a52.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "family_sharing_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123152949631ppz.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "family_sharing_plus_bvod_per_month_mts_ott_sdhd4k_b2c_v1_12_2022": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123153044361vzs.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_all_mts_2inet_bvod_per_month_mts_ott_sdhd4k_b2c_v1_02_2023": {
    "name": "Весь МТС. KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123145932978k76.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "family_sharing_for_you_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2023": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20231123152949631ppz.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_ozon_prem_bvod_per_month_mts_ott_sdhd4k_b2c_v1_02_2023": {
    "name": "KION + Ozon Premium",
    "description": "2 в 1: сериалы KION, фильмы, ТВ и Ozon Premium",
    "image": "2023040314075953047s.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_ozon_sport_bvod_per_month_mts_ott_sdhd4k_b2c_v1_02_2023": {
    "name": "KION + Ozon Premium 2023",
    "description": "2 в 1: сериалы KION, фильмы, ТВ и Ozon Premium",
    "image": "20230403140652951gqd.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_249_bvod_per_month_mts_ott_sdhd4k_b2c_v1_02_2023": {
    "name": "KION+Premium",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "20230803093122952dcs.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_249_bvod_per_month_mts_ott_sdhd4k_b2c_v2_02_2023": {
    "name": "KION+Premium",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "20230803093154554j55.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_249_bvod_per_month_mts_ott_sdhd4k_b2c_v3_02_2023": {
    "name": "KION+Premium",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "20230803093229604ml0.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_299_bvod_per_month_mts_ott_sdhd4k_b2c_v3_02_2023": {
    "name": "KION+Premium 2023",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "20230803093304277672.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_299_bvod_per_month_mts_ott_sdhd4k_b2c_v4_02_2023": {
    "name": "KION+Premium 2023",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "20230803093444297hra.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_299_bvod_per_month_mts_ott_sdhd4k_b2c_v5_02_2023": {
    "name": "KION+Premium 2023",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "20230803093624535vxi.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_exp_249_bvod_per_month_mts_ott_sdhd4k_b2c_v4_02_2023": {
    "name": "KION Cosmos",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов и МТС Premium",
    "image": "202308030937092777h6.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_corp_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2023": {
    "name": "KION",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов",
    "image": "2024032015525957557p.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_corp_plus_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2023": {
    "name": "KION",
    "description": "Сериалы KION и мировых студий, фильмы, 200+ ТВ-каналов",
    "image": "2024032015525957557p.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_for_mts_bvod_per_day_mts_ott_sdhd4k_b2c_v1_05_2023": {
    "name": "KION для МТС",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и более 200 ТВ-каналов",
    "image": "20240320155457990xct.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_amedia_bvod_per_month_mts_ott_sdhd4k_b2c_v2_05_2023": {
    "name": "KION + AMEDIATEKA",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_for_tv_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2023": {
    "name": "KION при продаже TV",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "20240320160328015bir.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_pay_tv_24d_12m_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2023": {
    "name": "KION при рассрочке 12 месяцев",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201604195414bm.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_pay_tv_24d_24m_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2023": {
    "name": "KION при рассрочке 24 месяца",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201604542710ce.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_pay_tv_32d_12m_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2023": {
    "name": "KION при рассрочке 12 месяцев",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201604195414bm.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_pay_tv_32d_24m_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2023": {
    "name": "KION при рассрочке 24 месяца",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201604542710ce.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_amedia_bvod_per_month_mts_ott_sdhd4k_b2c_v4_08_2023": {
    "name": "KION + AMEDIATEKA",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "start_svod_per_month_mts_ott_sdhd4k_b2c_v7_08_2023": {
    "name": "START",
    "description": "Подписка на START для KION",
    "image": "2023082808344796585f.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kino_all_mts_lite_svod_per_month_mts_ott_sdhd4k_b2c_v1_08_2023": {
    "name": "KION кино",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, мультфильмов и 20 ТВ-каналов",
    "image": "20230822144802970dp8.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_m2_connect_bvod_per_month_mts_ott_sdhd4k_b2c_v1_10_2023": {
    "name": "KION М2 Коннект",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и более 200 ТВ-каналов",
    "image": "20240320155944352ow7.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "livetv_movie_live_per_year_mts_ott_sdhd_b2c_v1_09_2023": {
    "name": "НАСТРОЙ КИНО! 12 месяцев",
    "description": "Для тех, кто готов смотреть кино 24/7",
    "image": "202305251324229444z2.jpg",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "livetv_match_live_per_year_mts_ott_sdhd_b2c_v1_09_2023": {
    "name": "МАТЧ ПРЕМЬЕР 12 месяцев",
    "description": "Всё о российском футболе",
    "image": "202104191939565530v8.png",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "livetv_football_live_per_year_mts_ott_sdhd_b2c_v2_09_2023": {
    "name": "МАТЧ! ФУТБОЛ 12 месяцев",
    "description": "ВСЕ о футболе в HD-качестве",
    "image": "20220720083018337fwf.jpg",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "adult_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_09_2023": {
    "name": "После полуночи 3 месяца",
    "description": "Для тех, кто не спит.",
    "image": "20220518144637341pfx.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "adult_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_09_2023": {
    "name": "После полуночи 6 месяцев",
    "description": "Для тех, кто не спит.",
    "image": "20220518144637341pfx.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "livetv_sport_live_per_multimonth6_mts_ott_sdhd_b2c_v1_09_2023": {
    "name": "Спорт 6 месяцев",
    "description": "Всё, что нужно любителю спорта",
    "image": "20220726210057428utc.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "livetv_sport_live_per_year_mts_ott_sdhd_b2c_v1_09_2023": {
    "name": "Спорт 12 месяцев",
    "description": "Всё, что нужно любителю спорта",
    "image": "20220726210057428utc.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "kion_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v2_10_2023": {
    "name": "Страшно низкая цена",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и более 200 ТВ-каналов",
    "image": "20240320161109342vgf.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "welcome_kion_svod_per_month_mts_ott_sdhd4k_b2c_v1_10_2023": {
    "name": "Знакомство с KION",
    "description": "Жизнь по Вызову, Тест на беременность, 13 клиническая и другие новинки",
    "image": "20231010144624592fd2.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "livetv_kionjam_live_per_month_mts_ott_sdhd_b2c_v1_11_2023": {
    "name": "Много Кино",
    "description": "Фильмы и сериалы с мировым именем, хиты KION non stop",
    "image": "20231109101224553s37.jpg",
    "ageRating": 16,
    "isBigPicture": 0
  },
  "livetv_kionjam_live_per_multimonth3_mts_ott_sdhd_b2c_v1_11_2023": {
    "name": "Много Кино 3 месяца",
    "description": "Фильмы и сериалы с мировым именем, хиты KION non stop",
    "image": "20231109101224553s37.jpg",
    "ageRating": 16,
    "isBigPicture": 0
  },
  "livetv_kionjam_live_per_year_mts_ott_sdhd_b2c_v1_11_2023": {
    "name": "Много Кино 12 месяцев",
    "description": "Фильмы и сериалы с мировым именем, хиты KION non stop",
    "image": "20231109101224553s37.jpg",
    "ageRating": 16,
    "isBigPicture": 0
  },
  "kion_prem_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v3_11_2023": {
    "name": "ТРИ ПО ЦЕНЕ ОДНОГО",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20240201080704295j7x.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "childhood_svod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_11_2023": {
    "name": "Детям 3 месяца",
    "description": "Любимые мультфильмы, фильмы и обучающие сериалы",
    "image": "20220518144740594gee.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "childhood_svod_per_year_mts_ott_sdhd4k_b2c_v1_11_2023": {
    "name": "Детям 12 месяцев",
    "description": "Любимые мультфильмы, фильмы и обучающие сериалы",
    "image": "20220518144740594gee.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "kion_prem_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v3_12_2023": {
    "name": "+3 месяца KION+PREMIUM",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240417081315073svg.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_year_mts_ott_sdhd4k_b2c_v3_12_2023": {
    "name": "KION+Premium 12 месяцев",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240219051934565y07.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_bvod_per_month_mts_ott_sdhd4k_b2c_v3_12_2023": {
    "name": "KION+Premium",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20231215150724607ll0.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_contr_bvod_per_month_mts_ott_sdhd4k_b2c_v1_01_2024": {
    "name": "KION+PREMIUM",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20240110161029253sns.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_ozon_prem_bvod_per_month_mts_ott_sdhd4k_b2c_v2_01_2024": {
    "name": "2 в 1: KION+OZON Premium",
    "description": "Сериалы KION, фильмы, ТВ, привилегии Ozon Premium",
    "image": "20240205085219623wl2.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_ozon_prem_bvod_per_month_mts_ott_sdhd4k_b2c_v3_02_2024": {
    "name": "2 в 1: KION+OZON Premium",
    "description": "Сериалы KION, фильмы, ТВ, привилегии Ozon Premium",
    "image": "20240205085219623wl2.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_prem_ozon_bvod_per_month_mts_ott_sdhd4k_b2c_v1_02_2024": {
    "name": "МАКСИМУМ ПРИВИЛЕГИЙ С KION",
    "description": "3 в 1: сериалы KION, фильмы, 200+ ТВ-каналов, МТС Premium и OZON Premium",
    "image": "20240401081300540ee5.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "amediateka_bvod_per_month_mts_ott_sdhd4k_b2c_v2_02_2024": {
    "name": "AMEDIATEKA акция",
    "description": "Самые ожидаемые сериалы планеты",
    "image": "20230130162804612iwu.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "kion_base_p_02_06_01_02_bvod_per_month_mts_ott_sdhd4k_b2c_v1_04_2024": {
    "name": "KION ЭЛЛКО",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и более 200 ТВ-каналов",
    "image": "20240417140647024w3w.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_02_06_01_03_bvod_per_month_mts_ott_sdhd4k_b2c_v1_04_2024": {
    "name": "KION ИнфоЛада",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и более 200 ТВ-каналов",
    "image": "20240417140304985lsm.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_02_06_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_04_2024": {
    "name": "KION М2 Коннект",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и более 200 ТВ-каналов",
    "image": "20240320155944352ow7.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_00_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_03_2024": {
    "name": "KION+Premium 12 месяцев",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240427142200453ld8.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_amediateka_s_01_00_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2024": {
    "name": "KION + AMEDIATEKA",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_00_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_03_2024": {
    "name": "ТРИ ПО ЦЕНЕ ОДНОГО",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20240201080704295j7x.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_ozon_s_01_00_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_03_2024": {
    "name": "МАКСИМУМ ПРИВИЛЕГИЙ С KION",
    "description": "3 в 1: сериалы KION, фильмы, 200+ ТВ-каналов, МТС Premium и OZON Premium",
    "image": "20240401081300540ee5.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_c_01_00_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_04_2024": {
    "name": "KION для вашего Тарифа №1",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240502080024985uz9.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_00_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2024": {
    "name": "KION+PREMIUM",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240427142200453ld8.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_ozon_s_01_00_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_05_2024": {
    "name": "МАКСИМУМ ПРИВИЛЕГИЙ С KION",
    "description": "3 в 1: сериалы KION, фильмы, 200+ ТВ-каналов, МТС Premium и OZON Premium",
    "image": "20240401081300540ee5.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_ozon_s_01_00_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_05_2024": {
    "name": "МАКСИМУМ ПРИВИЛЕГИЙ С KION",
    "description": "3 в 1: сериалы KION, фильмы, 200+ ТВ-каналов, МТС Premium и OZON Premium",
    "image": "20240401081300540ee5.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_ozon_s_01_00_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_05_2024": {
    "name": "МАКСИМУМ ПРИВИЛЕГИЙ С KION",
    "description": "3 в 1: сериалы KION, фильмы, 200+ ТВ-каналов, МТС Premium и OZON Premium",
    "image": "20240401081300540ee5.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_02_06_01_04_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2024": {
    "name": "KION Мегалайф",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "2024052214500702732d.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_02_06_01_05_bvod_per_month_mts_ott_sdhd4k_b2c_v1_05_2024": {
    "name": "KION Вектор телеком",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240522145255422g77.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_kino_ep_05_01_01_01_svod_per_month_mts_ott_sdhd4k_b2c_v1_06_2024": {
    "name": "KION кино. Прогрессоры",
    "description": "Эксклюзивные сериалы KION, фильмы, 20 ТВ-каналов",
    "image": "20240710133015426d4f.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_00_03_02_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+PREMIUM",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20240424075905448agi.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_00_06_02_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v2_07_2024": {
    "name": "+3 месяца KION+PREMIUM",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240417081315073svg.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_00_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+Premium",
    "description": "Оригинальные сериалы KION, тысячи фильмов, ТВ-каналы и привилегии МТС Premium",
    "image": "20240424075905448agi.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_00_12_02_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+PREMIUM 12 месяцев",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240219051934565y07.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_amediateka_s_01_00_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION + AMEDIATEKA",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_base_premium_amediateka_s_01_00_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION + AMEDIATEKA",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_base_premium_amediateka_s_01_00_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION + AMEDIATEKA",
    "description": "Сериалы KION и AMEDIATEKA, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20230803115839632ckf.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "add_adult_s_01_00_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "После полуночи",
    "description": "Для тех, кто не спит.",
    "image": "20220518144637341pfx.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "add_adult_s_01_00_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "После полуночи",
    "description": "Для тех, кто не спит.",
    "image": "20220518144637341pfx.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "add_adult_s_01_00_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "После полуночи",
    "description": "Для тех, кто не спит.",
    "image": "20220518144637341pfx.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "add_childhood_s_01_00_01_01_svod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "Детям",
    "description": "Любимые мультфильмы, фильмы и обучающие сериалы",
    "image": "20220518144740594gee.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "add_childhood_s_01_00_03_01_svod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "Детям",
    "description": "Любимые мультфильмы, фильмы и обучающие сериалы",
    "image": "20220518144740594gee.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "add_childhood_s_01_00_12_01_svod_per_year_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "Детям",
    "description": "Любимые мультфильмы, фильмы и обучающие сериалы",
    "image": "20220518144740594gee.jpg",
    "ageRating": 0,
    "isBigPicture": 0
  },
  "add_livetv_kionjam_s_01_00_01_01_live_per_month_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "Много Кино",
    "description": "Фильмы и сериалы с мировым именем, хиты KION non stop",
    "image": "20231109101224553s37.jpg",
    "ageRating": 16,
    "isBigPicture": 0
  },
  "add_livetv_kionjam_s_01_00_03_01_live_per_multimonth3_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "Много Кино",
    "description": "Фильмы и сериалы с мировым именем, хиты KION non stop",
    "image": "20231109101224553s37.jpg",
    "ageRating": 16,
    "isBigPicture": 0
  },
  "add_livetv_kionjam_s_01_00_12_01_live_per_year_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "Много Кино",
    "description": "Фильмы и сериалы с мировым именем, хиты KION non stop",
    "image": "20231109101224553s37.jpg",
    "ageRating": 16,
    "isBigPicture": 0
  },
  "add_livetv_movie_s_01_00_01_01_live_per_month_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "НАСТРОЙ КИНО!",
    "description": "Для тех, кто готов смотреть кино 24/7",
    "image": "202305251324229444z2.jpg",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "add_livetv_amedia_s_01_00_01_01_live_per_month_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "Амедиа Премиум HD",
    "description": "ТВ-каналы с зарубежными сериалами и фильмами",
    "image": "20230130065839543gw0.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "add_livetv_football_s_01_00_01_01_live_per_month_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "МАТЧ! ФУТБОЛ",
    "description": "ВСЕ о футболе в HD-качестве",
    "image": "20220720083018337fwf.jpg",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "add_livetv_sport_s_01_00_01_01_live_per_month_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "Спорт",
    "description": "Всё, что нужно любителю спорта",
    "image": "20220726210057428utc.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "add_livetv_sport_s_01_00_06_01_live_per_multimonth6_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "Спорт",
    "description": "Всё, что нужно любителю спорта",
    "image": "20220726210057428utc.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "add_livetv_sport_s_01_00_12_01_live_per_year_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "Спорт",
    "description": "Всё, что нужно любителю спорта",
    "image": "20220726210057428utc.jpg",
    "ageRating": 12,
    "isBigPicture": 0
  },
  "add_livetv_match_s_01_00_01_01_live_per_month_mts_ott_sdhd_b2c_v1_07_2024": {
    "name": "МАТЧ ПРЕМЬЕР",
    "description": "Всё о российском футболе",
    "image": "202104191939565530v8.png",
    "ageRating": 6,
    "isBigPicture": 0
  },
  "add_amediateka_s_01_00_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "AMEDIATEKA",
    "description": "Самые ожидаемые сериалы планеты",
    "image": "20230130162804612iwu.jpg",
    "ageRating": 18,
    "isBigPicture": 0
  },
  "add_ivi_s_01_00_01_01_svod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "Иви лайт",
    "description": "Специальная подписка IVI Лайт для Kion",
    "image": "20220727232508308nzi.jpg",
    "ageRating": 18,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_01_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_06_2024": {
    "name": "KION+PREMIUM 12 месяцев",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240404121840421b26.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_s_01_02_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_06_2024": {
    "name": "KION",
    "description": "Оригинальные сериалы KION, тысячи фильмов, мультфильмов и 200+ ТВ-каналов",
    "image": "202403201608546395k1.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_p_04_01_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+PREMIUM",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240710110131579ryf.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_p_04_01_01_02_bvod_per_month_mts_ott_sdhd4k_b2c_v2_07_2024": {
    "name": "KION+PREMIUM",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240710110250421u1g.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_p_04_01_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+PREMIUM 3 месяца",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240710110402029myb.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_p_04_01_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+PREMIUM 6 месяцев",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240710110509992qho.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_p_04_01_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+PREMIUM 12 месяцев",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240710110657025oqx.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_04_01_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "202407101011165835jh.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_04_01_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION 3 месяца",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240710101250428phc.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_04_01_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION 6 месяцев",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240710101417026owj.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_04_01_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION 12 месяцев",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240710101544990dwo.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_02_05_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_06_2024": {
    "name": "KION+PREMIUM 12 месяцев",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240625135255420jgl.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_02_01_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v2_07_2024": {
    "name": "KION+PREMIUM",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов, 200+ ТВ-каналов, МТС Premium",
    "image": "20240110161029253sns.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_s_02_01_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v2_07_2024": {
    "name": "KION акция",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240320155203031e3k.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_p_03_02_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+PREMIUM",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240701085531579fel.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_c_05_01_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240702074234988izo.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_02_01_01_01_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION Ингосстрах",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240710101841578jgi.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_02_06_01_06_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION Орионет",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "2024071010212694062v.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_s_01_01_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION 3 месяца",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "2024071010294277368t.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_s_01_01_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION 6 месяцев",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240710103229988wt7.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_s_01_01_12_01_bvod_per_year_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION 12 месяцев",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240710103622025a07.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_01_03_01_bvod_per_multimonth3_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+PREMIUM 3 месяца",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240710105444988j6i.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_01_06_01_bvod_per_multimonth6_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION+PREMIUM 6 месяцев",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "202407101056215788e0.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_premium_s_01_01_12_02_bvod_per_year_mts_ott_sdhd4k_b2c_v2_07_2024": {
    "name": "KION+PREMIUM 12 месяцев",
    "description": "Фильмы, сериалы от KION и мировых студий, 200+ ТВ-каналов, МТС Premium",
    "image": "20240710105750420in7.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  },
  "kion_base_p_02_06_01_07_bvod_per_month_mts_ott_sdhd4k_b2c_v1_07_2024": {
    "name": "KION Смарт",
    "description": "Эксклюзивные сериалы KION, тысячи фильмов и 200+ ТВ-каналов",
    "image": "20240711093710420miq.jpg",
    "ageRating": 12,
    "isBigPicture": 1
  }
}

function createSubscriptionObject({
                                    productId,
                                    discountPrice,
                                    price,
                                    promotion,
                                    tariffPeriod
                                  }: KionPromoPrice): Subscription | undefined {
  if (!PROMO[productId]) {
    return undefined
  }
  return {
    productId,
    discountPrice,
    price,
    promotion,
    tariffPeriod,
    ...PROMO[productId],
    transcription: transcriptRuEn(PROMO[productId].name)
  }
}

function splitPromo(filteredPromo: Subscription[]): [Subscription[], Subscription[]] {
  const big: Subscription[] = []
  const small: Subscription[] = []

  filteredPromo.forEach(item => item.isBigPicture === KION_PICTURE_TYPE.BIG ? big.push(item) : small.push(item))

  return [big, small]
}

export function joinPromoData(promos: KionPromoPrice[]): [Subscription[], Subscription[]] {
  const subscriptions = promos.map(createSubscriptionObject).filter((val: Subscription | undefined) => val !== undefined) as Subscription[]
  return splitPromo(subscriptions)
}

export function joinPromoDataDebug(): [Subscription[], Subscription[]] {
  const fakePromo = Object.keys(PROMO).map<KionPromoPriceFragment>(key => {
    return {
      productId: key,
      discountPrice: 29900,
      promotion: {timeToLiveValue: 1},
      price: 59900,
      tariffPeriod: KION_TARIFF_PERIOD.MONTHLY
    }
  }) as KionPromoPrice[]
  return splitPromo(fakePromo.map(createSubscriptionObject) as Subscription[])
}
