// import { defer, Observable } from 'rxjs'

import {
  WaterBase,
//   WBCFetchStatus,
  WBCInstance,
//   WBCLoggerAdditionalData,
//   WBCLoggerLevel,
  WBCOptions,
  WBCParameters,
} from '@kion/waterbase'

import { v4 as uuidv4 } from 'uuid'

declare global {
  interface Window {
    wbc: {
      instance: WBCInstance
    }
  }
}

const REQUEST_TIMEOUT = 250

export class WBProviderService<T extends WBCParameters = WBCParameters> {
  name = 'waterbase'
  isSupportSSR = false

  DEVICE_ID_COOKIE_NAME = 'kion_id'

  private wbcInstance: WBCInstance | null = null

  private initPromise: Promise<void> | null = null

  private loadScriptFailed = false

  private waterbaseConfig: any

  get fetchStatus() {
    if (!this.wbcInstance) {
      this.initError()
    }

    const WBCStatus = this.wbcInstance.fetchStatus
    switch (WBCStatus) {
      //   case WBCFetchStatus.IDLE:
      //     return REMOTE_CONFIG_FETCH_STATUS.IDLE;
      //   case WBCFetchStatus.PENDING:
      //     return REMOTE_CONFIG_FETCH_STATUS.PENDING;
      //   case WBCFetchStatus.SUCCESS:
      //     return REMOTE_CONFIG_FETCH_STATUS.SUCCESS;
      //   case WBCFetchStatus.FAILED:
      //     return REMOTE_CONFIG_FETCH_STATUS.FAILED;
      default:
        throw Error('No such remote config fetch status')
    }
  }

  get remoteConfig(): Readonly<T> {
    if (!this.wbcInstance) {
      this.initError()
    }

    return this.wbcInstance?.currentConfig as Readonly<T>
  }

  setCookie(name: string, value: string, days: number) {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
  }

  getCookie(name: string) {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  parseCookies = (rawCookie?: string): Record<string, string> => {
    if (rawCookie) {
      return rawCookie.split(';').reduce((acc: any, cur) => {
        const mappedCookie = cur.trim().split('=')
        acc[mappedCookie[0]] = mappedCookie[1]
        return { ...acc }
      }, {})
    } else {
      return {}
    }
  }

  get duid(): string {
    const currentValue = this.parseCookies(document.cookie)[
      this.DEVICE_ID_COOKIE_NAME
    ]
    if (currentValue) {
      // Если в куках уже есть duid, то возвращаем его
      return currentValue
    }
    // Если в куках нет duid, то генерируем новый и записываем в куки на 10 лет вперед
    const uuid = uuidv4()
    this.setCookie(this.DEVICE_ID_COOKIE_NAME, uuid, 365 * 10)
    return uuid
  }

//   fetchConfig(force = false) {
//     return defer(() => {
//       return this.zone.runOutsideAngular(async () => {
//         if (!this.wbcInstance) {
//           this.initError();
//         }
//         const response = await this.wbcInstance.fetchConfig(force);
//         return response.parameters as T;
//       });
//     });
//   }

  applyConfig(config: T): void {
    if (!this.wbcInstance) {
      this.initError()
    }

    return this.wbcInstance.setConfig({ parameters: config })
  }

  setDefaultConfig(config: T): void {
    if (!this.wbcInstance) {
      this.initError()
    }

    this.wbcInstance.setDefaultConfig(config)
  }

  getValue(key: string): any {
    if (!this.wbcInstance) {
      this.initError()
    }

    return this.wbcInstance.getValue(key) || ''
  }

  init(): Promise<void> {
    if (this.initPromise) {
      return this.initPromise
    }

    this.initPromise = this.initializer()
    return this.initPromise
  }

  private async initializer(): Promise<void> {
    try {
      // it is a requirement to load sdk from cdn in browser to have ability to change smth jit for smarts
      // because of a long release cycle
      await this.loadScript()
      this.wbcInstance = window.wbc.instance
    } catch (error) {
      this.wbcInstance = new WaterBase()
      // just to have ability to use global variable as if it was successfully loaded from cdn
      window.wbc = { instance: this.wbcInstance }
    }

    const wbcOptions = this.getWBCOptions()

    this.wbcInstance.init(wbcOptions)
  }

  private loadScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      const sdkUrl = this.waterbaseConfig.waterBaseSdkUrl
      if (!sdkUrl) {
        reject()
        return
      }

      // reject promise if script loading takes more than 250ms
      setTimeout(() => {
        this.loadScriptFailed = true
        reject()
      }, REQUEST_TIMEOUT)

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = sdkUrl
      script.onload = (): void => {
        // if script would be downloaded longer then 250ms it will be executed as well and overwrite existed wbc object
        // to avoid it we have to set valid global instance to be able to use public functions from the right object in the console
        if (this.loadScriptFailed && this.wbcInstance) {
          window.wbc.instance = this.wbcInstance
        }
        resolve()
      }
      script.onerror = (): void => {
        reject()
      }

      document.head.appendChild(script)
    })
  }

//   private loggerFn(
//     level: WBCLoggerLevel,
//     message: string,
//     additionalData?: WBCLoggerAdditionalData
//   ): void {
//     const httpStatusCode = additionalData?.httpStatus
//       ? Number(additionalData.httpStatus)
//       : undefined

//     const loggerOptions = {
//       provider: this.name,
//       level,
//       message,
//       httpStatusCode,
//     }
//   }

  private initError(): never {
    throw Error(`init method wasn't called. There is no WBC instance.`)
  }

  private getWBCOptions(): WBCOptions {
    const deviceId = this.duid

    if (!deviceId) {
      throw Error('deviceId is a mandatory field for WaterBase configuration')
    }

    const wbcOptions: WBCOptions = {
      appVersion: this.waterbaseConfig.appVersion,
      deviceModel: this.waterbaseConfig.deviceModel,
      token: this.waterbaseConfig.token,
      os: this.waterbaseConfig.os,
      apiUrl: this.waterbaseConfig.apiUrl,
      deviceId,
    //   logFn: this.loggerFn.bind(this),
    }

    return wbcOptions
  }
}
