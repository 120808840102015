import Vue from 'vue'
import { httpService } from '@/util/HttpAdapter'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import KionCode from '@/views/main/components/Code.vue'
import KionSuccess from '@/views/main/components/Success.vue'
import KionError from '@/views/main/components/Error.vue'
import KionPromoError from '@/views/main/components/PromoError.vue'
import KionMain from '@/views/main/Main.vue'
import KionOffer from '@/views/Offer.vue'
import KionRules from '@/views/Rules.vue'
import KionSsoAuth from '@/views/main/components/SsoAuth.vue'
import KionSubscriptions from '@/views/main/components/Subscriptions.vue'
import {Dictionary} from "vue-router/types/router";
// import KionDebug from '@/views/Debug.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: KionMain,
    children: [
      {
        path: '/',
        component: KionCode
      },
      {
        path: '/ssoauth',
        component: KionSsoAuth
      },
      {
        path: '/promo',
        component: KionPromoError,
        beforeEnter: codeGuard
      },
      {
        path: '/subscriptions',
        component: KionSubscriptions,
        beforeEnter: codeGuard
      },
      {
        path: '/success',
        component: KionSuccess,
        beforeEnter: codeGuard
      },
      {
        path: '/error',
        component: KionError
      }
    ]
  },
  {
    path: '/offer',
    name: 'Offer',
    component: KionOffer
  },
  {
    path: '/rules',
    name: 'Rules',
    component: KionRules
  },
  {
    path: '/:pathMatch(.*)',
    component: KionError
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function hasQueryParams(route: Route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  httpService.previousPage = window.location.href
  document.querySelector(".main.scrollable")?.scrollTo(0,0)

  if (hasQueryParams(from)) {
    window.sessionStorage.setItem("analyticsQuery", JSON.stringify(from.query))
  }
  if (hasQueryParams(to)) {
    window.sessionStorage.setItem("analyticsQuery", JSON.stringify(to.query))
  }

  next()
})

function codeGuard(to: Route, from: Route, next: NavigationGuardNext) {
  httpService.promoCode ? next() : next('/')
}

export default router
