

import { Component, Vue } from 'vue-property-decorator'
import KionButton from '@/components/Shared/Button.vue'
import KionRules from '@/views/Rules.vue'

@Component({
  components: {
    KionButton,
    KionRules
  }
})
export default class KionCopyright extends Vue {
  currentYear: number = new Date().getFullYear();

  goTo(name: 'Offer' | 'Rules') {
    let routeData = this.$router.resolve({ name });
    window.open(routeData.href, '_blank');
  }
}
